<template>
  <CCard>
    <CCardBody>
      <h3>
        <CIcon name="cil-pencil" /> {{ operationName }} Global Language
      </h3>
      <CAlert :color="alertType" v-if="message">
        {{ message }}
      </CAlert>
      <CRow>
        <CCol>
          <CInput label="Name" type="text" placeholder="Name" v-model="language.name" />
        </CCol>
        <CCol>
          <CInput label="Language Code" type="text" v-model="language.languageCode" />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CSelect label="DateTime Format" :options="dateTimeFormats" :value.sync="language.datetimeFormat">
          </CSelect>
        </CCol>
      </CRow>
      <CRow>
        <CCol col="6"></CCol>
        <CCol class="d-flex justify-content-end">
          <CButton color="secondary" class="btn-lg" @click="goBack" style="margin-right: 15px">
            <CIcon name="cil-list" />Back to the list
          </CButton>
          <CButton color="primary" @click="save()" class="btn-lg">
            <CIcon name="cil-save" /> Save
          </CButton>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
import Vue from 'vue';
export default {
  name: "CreateOrUpdateGlobalLanguage",
  data: () => {
    return {
      token: localStorage.getItem("api_token"),
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      operationName: "Create New",
      dateTimeFormats: [
        {
          value: null,
          label: "Choose"
        },
        {
          value: "dd.MM.yyyy HH:mm",
          label: "dd.MM.yyyy hh:mm"
        },
        {
          value: "dd.MM.yyyy hh:mm tt",
          label: "dd.MM.yyyy hh:mm AM/PM"
        },
        {
          value: "dd-MM-yyyy HH:mm",
          label: "dd-MM-yyyy hh:mm"
        },
        {
          value: "dd-MM-yyyy hh:mm tt",
          label: "dd-MM-yyyy hh:mm AM/PM"
        },
        {
          value: "MM/dd/yyyy HH:mm",
          label: "MM/dd/yyyy hh:mm"
        },
        {
          value: "MM/dd/yyyy hh:mm tt",
          label: "MM/dd/yyyy hh:mm AM/PM"
        },
        {
          value: "MM-dd-yyyy HH:mm",
          label: "MM-dd-yyyy hh:mm"
        },
        {
          value: "MM-dd-yyyy hh:mm tt",
          label: "MM-dd-yyyy hh:mm AM/PM"
        },
      ],
      //dateTimeFormats: ["Choose","dd.MM.yyyy hh:mm", "dd-MM-yyyy hh:mm","dd.MM.yyyy hh:mm tt", "dd-MM-yyyy hh:mm tt", "MM/dd/yyyy hh:mm tt", "MM/dd/yyyy hh:mm", "MM-dd-yyyy hh:mmtt","MM-dd-yyyy hh:mm"],
      language: {
        id: "00000000-0000-0000-0000-000000000000",
        name: null,
        languageCode: null,
        datetimeFormat: null
      },

      alertType: "danger",
      message: null,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    save() {
      let self = this;
      if (!self.language.name || !self.language.languageCode) {
        self.failed = true;
        self.message = "Please enter all needed fields.";
        self.loading = false;
                  self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
        return;
      }
      if(self.language.datetimeFormat){
        self.language.datetimeFormatWidget = self.dateTimeFormats.find(format => format.value == self.language.datetimeFormat).label;
      }
      axios
        .post(`${this.$apiAdress}/v1/Language`, self.language)
        .then((response) => {
          self.language.id = response.data;
          axios
        .get(`${self.$apiAdress}/v1/Language/GetByCode/en-US`)
        .then(function (response) {
          Vue.prototype.$dateFormat = response.data.datetimeFormat.replace("dd", "DD").replace("tt", "A");
          Vue.prototype.$dateFormatInput = response.data.datetimeFormat.replace("tt", "a");
        });
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully updated language.";
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },

    get(id) {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/Language/${id}`)
        .then(function (response) {
          self.language = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
  },
  mounted: function () {
    // Edit OR Create
    if (this.$route.params.id != this.emptyGuid) {
      this.operationName = "Edit";
      this.get(this.$route.params.id);
    }
  },
};
</script>
